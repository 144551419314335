import { useAppDispatch, useAppSelector } from "hooks";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FeaturesFlags } from "helpers";
import {
	getLimitedDeals,
	onSetStickyDIPData,
	selectStickyDIPState
} from "redux/slices";
import { useEnvContext, useWebStoreContext } from "contexts";
import { useGetOffersQuery, featureTrackerAPI } from "redux/apis";
import { ShopContext } from "pages/StoreFront";
import { PageContent } from "components/PageContent";
import { IItem } from "../SingleItems";
import EnlessOffer from "../EndlessOffer";
import { WaterFallSale } from "../WaterFallSale";
import StickyDIP from "components/StickyDIP";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, SwiperOptions } from "swiper";
import SectionHeader from "components/Section/SectionHeader";
import { LimitedDealsIcon } from "utils/svgIcons";
import { SwiperContainer } from "styles/general_styles";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";

export const LimitedDealsSection = () => {

	const dispatch = useAppDispatch();
	const { variant } = useDialogModalRedux();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { sectionMenuData } = useContext(ShopContext);
	const { authToken, userTracker } = useWebStoreContext();
	const swiperRef = useRef<SwiperRef | null>(null);

	const [swiperSlides, setSwiperSlides] = useState<Array<JSX.Element>>([]);

	const swiperParams: SwiperOptions = {
		//autoHeight: true,
		autoplay: {
			delay: 7000,
			pauseOnMouseEnter: true,
			disableOnInteraction: variant === WsDialogs.XSOLLA_PURCHASE ? true : false,
		},
		modules: [
			Autoplay,
			Pagination,
		],
		navigation: true,
		pagination: {
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 4,
		},
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 30,
	};

	const { data: stickyData } = useAppSelector(selectStickyDIPState);
	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: !!authToken?.value
	});
	const { data: EndlessOfferData } =
		featureTrackerAPI.endpoints.getFeatureTracker.useQueryState({
			FeatureType: "endless-offer",
			isAuthenticated: !!authToken?.value
		});
	const { data: waterfallData } =
		featureTrackerAPI.endpoints.getFeatureTracker.useQueryState({
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		});

	const isWaterfallSaleActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.WATERFALL_SALES)
		);
	};
	const isEndlessOfferActive = () => {
		return (
			!!authToken?.value && isFeatureFlagEnabled(FeaturesFlags.ENDLESS_OFFER)
		);
	};
	const isStickyDipActive = () => {
		return isFeatureFlagEnabled(FeaturesFlags.STICKY_DIPS);
	};

	const section = sectionMenuData.find((item: IItem) => {
		if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
			return item.name === "waterfall_sale";
		}
		if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
			return item.name === "endless_offer";
		}
		if (isStickyDipActive() && stickyData) {
			return item.name === "limited_deals";
		}
		return false;
	});

	useMemo(() => {
		if (variant === WsDialogs.XSOLLA_PURCHASE) {
			swiperRef.current?.swiper.autoplay.stop();
		} else {
			swiperRef.current?.swiper.autoplay.start();
		}
	}, [variant]);

	useEffect(() => {
		if (!userTracker) {
			dispatch(onSetStickyDIPData(undefined));
		} else {
			dispatch(getLimitedDeals());
		}
	}, [userTracker]);

	useEffect(() => {
		const swiperContent: Array<JSX.Element> = [];
		if (isWaterfallSaleActive() && waterfallData?.sessionTracker) {
			swiperContent.push(<WaterFallSale />);
		}
		if (isEndlessOfferActive() && EndlessOfferData?.tracker) {
			swiperContent.push(<EnlessOffer />);
		}
		if (isStickyDipActive() && stickyData) {
			type OfferType = Omit<IShopPurchaseOffer, "onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText">;
			// amount of space available in the carousel
			const availableSlots = 5 - swiperContent.length;
			const sortedStickyDataByDates =
				Object.entries(stickyData)
				.slice(0, availableSlots)
				.sort(([keyA, arrayA]: [string, OfferType[]], [keyB, arrayB]: [string, OfferType[]]) => {
					console.log(keyA, keyB)
					//* Verify both arrays have elements.
					if (!arrayA[0] || !arrayB[0]) {
						//* If array is empty, no changes apply.
						return 0;
					}
					const dateA = arrayA[0].timerExpirationDate ? new Date(arrayA[0].timerExpirationDate).getTime() : null;
					const dateB = arrayB[0].timerExpirationDate ? new Date(arrayB[0].timerExpirationDate).getTime() : null;
					//* If dateA or dateB are null sort accordingly
					if (dateA === null && dateB === null) return 0; // if both are null they are equal
					if (dateA === null) return 1;  // If dateA is null it's placed after dateB
					if (dateB === null) return -1; // If dateB is null it's placed after dateA

					//* If both have valid dates we compare
					return dateA - dateB;
				});

			Object.values(Object.fromEntries(sortedStickyDataByDates)).forEach(sd => {
					swiperContent.push(<StickyDIP data={sd} />);
			});
		}
		setSwiperSlides(swiperContent);
	}, [stickyData, EndlessOfferData, waterfallData]);

	if (!section || !purchaseOffers) {
		return <></>;
	}

	return (
		<section ref={section?.sectionRef}>
			<PageContent source={"store_page"} sxContainer={{ paddingBottom: 0 }}>
				<SectionHeader
					text="LIMITED DEALS"
					icon={<LimitedDealsIcon />}
					sx={{ marginBottom: { xs: "0px", sm: "10px" } }}
				/>
				<SwiperContainer>
					<Swiper ref={swiperRef} {...swiperParams} className="tiki-swiper">
						{swiperSlides.map((ss, i) => (
							<SwiperSlide key={i}>{ss}</SwiperSlide>
						))}
					</Swiper>
				</SwiperContainer>
			</PageContent>
		</section>
	);
};
