import { PREFIX } from "./helper";
import { RootState } from "redux/store";
import { getLimitedDeals } from "./thunks";
import { initialState } from "./initial-state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";

export const stickyDIPSlice = createSlice({
	name: PREFIX,
	initialState,
	reducers: {
		onSetStickyDIPData: (
			state,
			action: PayloadAction<
				| Omit<
						IShopPurchaseOffer,
						| "onlyNonPayers"
						| "priority"
						| "purchaseButtonBannerText"
						| "bannerText"
					>
				| undefined
			>
		) => {
			if (action.payload === undefined) {
				state.data = action.payload;
				return;
			}

			if (state.data) {
				const updatedData = state.data;
				delete updatedData[action.payload.campaignGroup];
				state.data = updatedData;
			}
		},
		onSetStickyDIPsDisplayed: (state, action) => {
			state.stickyDIPsDisplayed = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getLimitedDeals.fulfilled, (state, action) => {
			const data = action.payload;
			if (!data || !("offers" in data)) {
				state.data = undefined;
				state.isBogo = false;
				state.isBuyItAll = false;
				return;
			}

			const groupedByCampaignGroup = data.offers.reduce<
				Record<
					string,
					Omit<
						IShopPurchaseOffer,
						| "priority"
						| "onlyNonPayers"
						| "purchaseButtonBannerText"
						| "bannerText"
					>[]
				>
			>((acc, offer) => {
				const campaignGroup = offer.campaignGroup;

				// If group doesn't exist we create it
				if (!acc[campaignGroup]) acc[campaignGroup] = [];
				// Add offer to the correct group
				acc[campaignGroup].push(offer);

				return acc;
			}, {});

			//state.isBogo = typeof groupedByCampaignGroup.offers[0].rewardMultiplier !== "undefined";
			//state.isBuyItAll = !!groupedByCampaignGroup.offers.slice(-1)[0].isBuyItAll;
			state.data = groupedByCampaignGroup;
		});
	}
});

export const { onSetStickyDIPData, onSetStickyDIPsDisplayed } =
	stickyDIPSlice.actions;
export const selectStickyDIPState = (state: RootState) => state.stickyDip;
export default stickyDIPSlice.reducer;
export * from "./types";
export * from "./thunks";
