import { IOffer, ITierStatus, WaterFallCard } from "./lib/WaterFallCard";
import {
	BgBottomBrush,
	BgTopBrush,
	Container,
	Image,
	OffersContainer,
	timerStyleExtraSmall,
	timerStyleMobile,
	timerStyleTablet,
	Title,
	Wrapper
} from "./styles";
import { Timer } from "./svg";
import { useEffect, useState } from "react";
import GreenArrow from "../../../../assets/waterfall-sale/green_arrow.svg";
import PlusIcon from "../../../../assets/waterfall-sale/plus_icon.svg";
import { useWebStoreContext } from "contexts";
import { useGetOffersQuery, useGetFeatureTrackerQuery } from "redux/apis";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectDialogState, setWaterfallResponse } from "redux/slices";
import CountdownV2 from "components/CountdownV2";
import { timerStyle } from "./styles";
import useBreakpoints from "hooks/useBreakpoints";
import { useMediaQuery } from "@mui/material";

export const WaterFallSale = () => {
	const dispatch = useAppDispatch();
	const { isLgDown, isSmUp, isSmDown } = useBreakpoints();
	const { nowTime, authToken } = useWebStoreContext();
	const { props } = useAppSelector(selectDialogState);
	const isExtraSmall = useMediaQuery("(max-width: 390px)");
	const [refetchOnFocus, setRefetchOnFocus] = useState(true);
	const { isLoading: isPurchaseOffersLoading, data: purchaseOffers } =
		useGetOffersQuery({
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		});

	const {
		isLoading: isSessionTrackerLoading,
		data,
		isError,
		refetch
	} = useGetFeatureTrackerQuery(
		{
			FeatureType: "waterfall-sale",
			isAuthenticated: !!authToken?.value
		},
		{ refetchOnFocus }
	);

	const getOfferbyId = (offerId: string) => {
		return purchaseOffers?.offers.find(
			(offer: IOffer) => offer?.id === offerId
		);
	};

	const handleEnd = () => {
		setTimeout(() => {
			refetch();
		}, 1000);
	};

	const handleCompleted = () => {
		setTimeout(() => {
			refetch();
		}, 5000);
	};

	useEffect(() => {
		if (data?.sessionTracker?.complete) {
			setRefetchOnFocus(false);
		} else {
			setRefetchOnFocus(true);
		}
	}, [data?.sessionTracker]);

	useEffect(() => {
		if (data?.sessionTracker?.complete && !props) {
			handleCompleted();
		}
	}, [data?.sessionTracker, props]);

	useEffect(() => {
		if (data?.success) {
			dispatch(setWaterfallResponse(data));
		}
	}, [data]);

	if (
		isPurchaseOffersLoading ||
		isSessionTrackerLoading ||
		isError ||
		!data?.sessionTracker
	) {
		return <></>;
	}

	const { sessionTracker } = data;

	return (
		<>
			<Container
				BackgroundImage={sessionTracker?.backgroundImageUrl}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}
			>
				<BgTopBrush />
				{/* <BackgroundImage src={sessionTracker?.backgroundImageUrl} /> */}
				<CountdownV2
					icon={<Timer />}
					text={"Offer ends in:"}
					startTime={nowTime}
					endTime={sessionTracker?.endTime}
					onEnd={handleEnd}
					className={"centeredTimer"}
					labelStyle={{ color: "#EB5757", marginLeft: 6 }}
					style={{
						...isExtraSmall
							? timerStyleExtraSmall
							: isSmDown
								? timerStyleMobile
								: isSmUp && isLgDown
									? timerStyleTablet
									: timerStyle
					}}
				/>
				{/* <Content> */}
					<Title BackgroundImage={sessionTracker?.titleImageUrl} />
					<OffersContainer>
						{sessionTracker.tierStatuses?.map(
							(tierStatus: ITierStatus, index: number) => (
								<Wrapper key={index}>
									<WaterFallCard
										saleId={sessionTracker?.saleId}
										tierStatuses={sessionTracker.tierStatuses}
										tierStatus={tierStatus}
										offer={getOfferbyId(tierStatus?.tier?.offerId)}
									/>
									{index < sessionTracker.tierStatuses?.length - 1 && (
										<Image
											src={
												sessionTracker.tierStatuses[index + 1]?.tier?.offerId
													? GreenArrow
													: PlusIcon
											}
										/>
									)}
								</Wrapper>
							)
						)}
					</OffersContainer>
				{/* </Content> */}
				<BgBottomBrush />
			</Container>
		</>
	);
};
