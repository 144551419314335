import Button from "components/Buttons/ThemeButton";
import {
	Container,
	Description,
	DescriptionContainer,
	ItemImage,
	Items,
	LockIcon,
	LoyalBox,
	LoyalBoxWrap,
	Percentage,
	RewardAmount,
	RewardItem
} from "./styles";
import CheckIcon from "../../../../../../assets/waterfall-sale/check_icon.svg";
import Chain from "../../../../../../assets/waterfall-sale/chain_v3.svg";
import { CrownIcon } from "utils/svgIcons";
import { useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { WsDialogs } from "interfaces";
import "animate.css";
import useRewardsById from "hooks/useRewardsById";
import { selectPoisBountyV2State } from "redux/slices";
import { useAppSelector } from "hooks";
import { CardFinalMessage } from "styles/general_styles";
import useBreakpoints from "hooks/useBreakpoints";

export interface ITierStatus {
	index: number;
	completed: boolean;
	tier: {
		rewardId: string;
		imageUrl: string;
		title: string;
		offerId: string;
	};
}

export interface IOffer {
	rewardId: string;
	id: string;
	price: number;
	loyaltyPointReward: Date;
}
interface Props {
	saleId: string;
	tierStatuses: ITierStatus[];
	tierStatus: ITierStatus;
	offer: {
		rewardId: string;
		id: string;
		price: number;
		loyaltyPointReward: Date;
	};
	inputMockChecked?: boolean;
}

export const WaterFallCard: React.FC<Props> = ({
	saleId,
	tierStatuses,
	tierStatus,
	offer
}) => {
	const {
		wsStoreBuy,
		authToken,
		suppressOrderConfirmation,
		postUserMetadata,
		userTracker
	} = useWebStoreContext();
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);

	const { isXlUp, isLgUp, isMdUp, isSmUp } = useBreakpoints();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const displayRewards = useRewardsById(
		offer?.rewardId || tierStatus?.tier?.rewardId
	);
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	const isPreviousTierCompleted = (reverseValue = 1) => {
		return tierStatuses[tierStatus?.index - reverseValue]
			? tierStatuses[tierStatus?.index - reverseValue]?.completed
			: true;
	};

	const separateString = (cadena: string) => {
		const percentage = cadena.replace(/[a-zA-Z!]/g, "");
		const description = cadena.replace(/[0-9%]/g, "");
		return {
			percentage,
			description
		};
	};

	const onClickBuy = async () => {
		if (!authToken?.value) {
			return;
		}
		const invoice = await wsStoreBuy({
			offerId: offer.id,
			waterfallSaleId: saleId,
			waterfallSaleTierIndex: tierStatus?.index
		});

		//console.log("test_05", invoice);

		if (invoice) {
			openDialog(WsDialogs.XSOLLA_PURCHASE, {
				xsollaToken: invoice.xsollaToken,
				xsollaHost: invoice.xsollaHost,
				section: "shop",
				source: "waterfall",
				suppressOrderConfirmation,
				postUserMetadata,

				onPurchaseStarted: (data) => {
					reportEvent("purchase_started", {
						section_name: "limited_deals",
						offer_id: `${saleId}-${tierStatus?.index}`,
						price_in_cents: offer?.price * 100,
						reward_id: "123",
						invoice_id: invoice?.id,
						paystation_id: data?.payStation
					});
				},
				onPurchaseSuccess: () => {
					reportEvent("purchase_success", {
						invoice_id: invoice?.id
					});
				}
			});
		}
	};

	const formatedTitle = separateString(tierStatus?.tier?.title);

	return (
		<Container image={tierStatus?.tier?.imageUrl}>
			<DescriptionContainer>
				{tierStatus.index === 0 ? (
					<Description>{tierStatus?.tier?.title}</Description>
				) : (
					<>
						<Percentage>
							{!tierStatus?.tier?.rewardId && formatedTitle.percentage}
						</Percentage>
						<Description
							variant={tierStatus?.tier?.rewardId ? "freeItem" : "item"}
						>
							{formatedTitle.description}
						</Description>
					</>
				)}
			</DescriptionContainer>
			{tierStatus?.completed ? (
				<Items isOffer={false}>
					<img
						className="animate__animated animate__rubberBand"
						src={CheckIcon}
					/>
				</Items>
			) : (
				<>
					<Items isOffer={tierStatus?.tier?.rewardId ? false : true}>
						{displayRewards?.map((displayReward) => (
							<RewardItem
								key={displayReward.id}
								width={"50%"}
								filter={
									isPreviousTierCompleted() ||
										(isPreviousTierCompleted(2) && tierStatus?.tier?.rewardId)
										? 0
										: 100 //Disabled
								}
							>
								<ItemImage
									className="item-icon"
									src={displayReward.imageUrl}
									alt=""
									loyaltyBoxExtraMessage={isPoisActive}
								/>
								<RewardAmount className="description">
									{displayReward.description}
								</RewardAmount>
							</RewardItem>
						))}
					</Items>
					{offer && (
						<>
							<LoyalBoxWrap loyaltyBoxExtraMessage={isPoisActive}>
								<LoyalBox
									loyaltyBoxExtraMessage={isPoisActive}
									sx={{
										fontSize: {
											xs: isPoisActive ? "1.9vw" : "2.5vw",
											sm: isPoisActive ? "1vw" : "1.3vw",
											lg: isPoisActive ? "0.9vw" : "1.3vw",
											xl: isPoisActive ? "0.9vw" : "20px",
										}
									}}
								>
									<div>
										Earn
										<CrownIcon
											width={
												isXlUp
													? 22
													: isLgUp
														? 16
														: isMdUp
															? 14
															: isSmUp
																? 12
																: 10
											}
										/>
										{` ${offer?.loyaltyPointReward.toLocaleString("en-US")}`}
									</div>
									{isPoisActive && (
										<CardFinalMessage color="#FBBD05">
											Contribute to Poi’s Bounty
										</CardFinalMessage>
									)}
								</LoyalBox>
							</LoyalBoxWrap>
							<Button
								variant="contained"
								color="success"
								size="large"
								disabled={!isPreviousTierCompleted()}
								onClick={onClickBuy}
								sx={{
									position: "relative",
									width: "140px",
									height: "15.7%",
									marginTop: "10px"
								}}
							>
								{offer?.price} USD
								{!isPreviousTierCompleted() && <LockIcon src={Chain} />}
							</Button>
						</>
					)}
				</>
			)}
		</Container>
	);
};
