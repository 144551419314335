import { Timer } from "./svg";
import { Fragment } from "react";
import { Bogo, BuyItAll, Offers } from "./lib";
import { useWebStoreContext } from "contexts";
import { Typography, useMediaQuery } from "@mui/material";
import CountdownV2 from "components/CountdownV2";
import { useAppDispatch } from "hooks";
import { getLimitedDeals, onSetStickyDIPData } from "redux/slices";
import {
	Container,
	timerStyle,
	BgBrush,
	Image,
	timerStyleMobile
} from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import bgBrush from "assets/limited-deals/bg-brush.png";
import bgBrushMobile from "assets/limited-deals/bg-brush-mobile.png";
import { getBackgroundUrl } from "components/DIP";
import classNames from "classnames";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";

interface StickyDIPProps {
	data?: Omit<IShopPurchaseOffer, "onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText">[];
}

const StickyDIP: React.FC<StickyDIPProps> = ({ data }: StickyDIPProps) => {
	const dispatch = useAppDispatch();
	const { nowTime } = useWebStoreContext();
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);

	const isLowWidth = useMediaQuery("(max-width:733px)");
	const isMobile = isLowWidth;

	const endTime = data?.[0].timerExpirationDate as string;
	const showCountDown = nowTime?.isBefore(endTime);
	const isBogo = data?.[0]?.dip?.isBogo;
	const isBuyItAll = data?.[0]?.dip?.isBuyItAll;
	const className = classNames({
		"buy-it-all": isBuyItAll
	});

	return data && showCountDown ? (
		<Fragment>
			<Container className={`${className} animate__animated`}>
				<Image
					className={className}
					src={getBackgroundUrl(
						data?.[0]?.dip?.imageUrl as string,
						isMobile ? "small" : "large"
					)}
				/>
				<BgBrush src={isMobile ? bgBrushMobile : bgBrush} />
				<CountdownV2
					icon={<Timer />}
					endTime={endTime}
					style={{
						...(isMobile ? timerStyleMobile : timerStyle),
						...(isBuyItAll && !isMobile ? { top: "7%" } : {})
					}}
					startTime={nowTime}
					text="Offer ends in: "
					labelStyle={{ color: "#EB5757", marginLeft: 6 }}
					onEnd={() => {
						dispatch(onSetStickyDIPData(data[0]));
						dispatch(getLimitedDeals());
					}}
				/>
				{showOfferId && (
					<Typography
						sx={{
							fontWeight: 700,
							color: "red",
							fontSize: "12px",
							height: "auto",
							position: "absolute",
							top: 50,
							left: "1.1%"
						}}
					>
						{data?.[0]?.dip?.id}
					</Typography>
				)}
				{isBogo ? <Bogo data={data} /> : isBuyItAll ? <BuyItAll data={data} /> : <Offers data={data} />}
			</Container>
		</Fragment>
	) : (
		null
	);
};

export default StickyDIP;
